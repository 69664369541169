<template>
  <span>
    <v-dialog
      v-model="showDialog"
      max-width="850"
      @input="setMotdReaded($event)"
    >
      <v-card>
        <v-card-title class="headline">
          Mensagem do Dia
        </v-card-title>

        <v-card-text>
          <p>
            Olá! Estamos sempre em busca de aprimorar a plataforma de validação
            de dados do IndGer e tornar sua experiência ainda melhor. Para isso,
            contamos com a sua ajuda! Preencha
            <a href="https://forms.office.com/r/8BWcg03MVm" target="_blank">
              este formulário
            </a>
            e nos conte como podemos evoluir e surpreender você em 2025. Sua
            opinião é essencial para construirmos um futuro mais eficiente e
            inovador juntos!
          </p>

          <v-btn
            class="mt-3"
            color="primary"
            onclick="window.open('https://forms.office.com/r/8BWcg03MVm', '_blank')"
          >
            <v-icon left>
              mdi-open-in-new
            </v-icon>
            Responder formulário
          </v-btn>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="setMotdReaded(false)">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  export default {
    name: "Motd",
    props: {
      motdReaded: {
        type: Boolean,
        required: true,
      },
    },
    data: () => ({
      //
    }),
    computed: {
      showDialog() {
        return !this.motdReaded;
      },
    },
    methods: {
      setMotdReaded(value) {
        this.$store.dispatch("setMotdReaded", !value);
      },
    },
  };
</script>

<style></style>
